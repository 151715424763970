<template>
    <widget-container class="widget-iframe-container" name="Iframe" v-if="widget" :widget="widget">
        <widget-ratio-container v-if="widget.loaded" :width="widget.width" :height="widget.height" :min-margins="0" :background-color="widget.config.style.backgroundColor" :background-image="widget.config.style.backgroundImage">
            <widget-block type="title" direction="vertical" :class="{'edit': mode === 'edit'}" :block-style="widget.config.style.number">
                {{currentNumber}}
                <template v-if="mode === `edit`">
                    <div class="mt-2 f-500" style="font-size: 25pt;" v-tr>Press Enter, /, *, or Esc to Apply</div>
                </template>
            </widget-block>
        </widget-ratio-container>
        <audio ref="audioSound" controls :src="require(`@/assets/sounds/door-bell-ring.mp3`)" style="width: 0; height: 0; visibility: hidden;"/>
    </widget-container>
</template>

<script>
import WidgetBlock from "@/layout/widget-block.vue";
import WidgetRatioContainer from "@/layout/widget-ratio-container.vue";
import {Widget} from "../helpers/Widget.js";

export default {
    name: `widget-number-ticket`,
    components: {WidgetBlock, WidgetRatioContainer},
    data() {
        return {
            widget: null,
            mode: `default`,
            currentNumber: 0
        }
    },
    async mounted() {
        this.widget = new Widget(`number-ticket`, this.$route.params.id);
        await this.widget.load();
        if (localStorage.getItem(`currentNumber`) > 0) {
            this.currentNumber = parseInt(localStorage.getItem(`currentNumber`));
            if (isNaN(this.currentNumber)) {
                this.currentNumber = 0;
            }
        }
        window.addEventListener(`keydown`, this.onKeyPressed.bind(this));
        window.focus();
    },
    beforeDestroy() {
        window.removeEventListener(`keydown`, this.onKeyPressed);
    },
    methods: {
        setCurrentNumber(value) {
            this.currentNumber = Math.max(value, 0);
            localStorage.setItem(`currentNumber`, value);
        },
        removeLastNumber() {
            this.setCurrentNumber(Math.trunc(this.currentNumber / 10));
        },
        onKeyPressed(e) {
            if (this.mode === `edit`) {
                if (e.key === `Enter` || e.key === `Escape` || e.key === `*`) {
                    this.mode = `default`;
                }
                if (e.key === `Esc`) {
                    this.mode = `default`;
                }
                if (e.key === `Backspace` || e.key === `Delete` || e.key === `/` || e.key === `.`) {
                    this.removeLastNumber();
                }
                if (e.key >= 0) {
                    this.setCurrentNumber(parseInt(this.currentNumber + e.key));
                }
            } else {
                if (e.key === `Escape` || e.key === `Backspace` || e.key === `Delete` || e.key === `*`) {
                    this.mode = `edit`;
                    if (e.key === `Backspace`) {
                        this.removeLastNumber();
                    }
                }
                if (e.key === `0`) {
                    this.setCurrentNumber(0);
                }
                if (e.key === `Enter` || e.key === `+`) {
                    this.setCurrentNumber(this.currentNumber + 1);
                    this.playSound();
                }
                if (e.key === `-`) {
                    this.setCurrentNumber(this.currentNumber - 1);
                }
            }
        },
        playSound() {
            if (this.$refs.audioSound && this.$refs.audioSound.paused) {
                this.$refs.audioSound.currentTime = 0;// To start from beginning
                this.$refs.audioSound.play();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.edit {
    border: 5px dashed;
}
</style>
